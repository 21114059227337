import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';

import MainClass from '../../../../main/MainClass';

import TextInputComponent from '../../../Components/Elements/TextInput/TextInputComponent';
import DropDownComponent from '../../../Components/Elements/DropDown/DropDownComponent';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});

const initialStateObject = {
  raschetnaya_temperature_select: 0,
  raschetnoe_davlenie_select: 1,
  material_plastin: 'AISI316',
  material_prokladok: ['EPDM', 'VITON'],
  filtr_select: 0,
  filtrTypes: [],
};
const raschetnaya_temperatureValue = [150, 180];
const raschetnoe_davlenieValue = [10, 16];
const material_prokladokValues = ['EPDM', 'VITON'];
const raschetnaya_temperatureTypes = ['150 °C', '180 °C'];
const raschetnoe_davlenieTypes = ['10 атм', '16 атм'];
/*
const filtrTypes = [
  'Искать среди всех',
  'ЭТ-004С Ду 32мм',
  'ЭТ-008С Ду 32мм',
  'ЭТ-007С Ду 50мм',
  'ЭТ-014С Ду 50мм',
  'ЭТ-020С Ду 50мм',
  'ЭТ-019С Ду 65мм',
  'ЭТ-021С Ду 100мм',
  'ЭТ-022С Ду 100мм',
  'ЭТ-047С Ду 100мм',
  'ЭТ-041С Ду 150мм',
  'ЭТ-062С Ду 150мм',
  'ЭТ-065С Ду 200мм',
  'ЭТ-121С Ду 300мм',
  'ЭТ-188С Ду 300мм',
  'ЭТ-086С Ду 150мм',
  'ЭТ-100С Ду 200мм',
  'ЭТ-031С Ду 100мм',
  'ЭТ-043С Ду 200мм',
  'ЭТ-042С Ду 150мм',
];
*/

const RPTOPanel2Component = (props) => {
  //console.log('RPTOPanel2Component');

  const [state, setState] = useState(initialStateObject);

  useEffect(() => {
    MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject = state;
  }, [state]);

  useEffect(() => {
    if (props.ptoList !== '') {
      setState((prevState) => ({
        ...prevState,
        filtrTypes: props.ptoList,
      }));
    }
  }, [props.ptoList]);

  const onDataInputChanged = (event) => {
    //console.log('onDataInputChanged id:' + event.target.id + ' val: ' + event.target.value);

    props.dataChangeEventHandler();

    const id = event.target.id;
    const val = event.target.value;

    setState((prevState) => ({
      ...prevState,
      [id]: val,
    }));

    var infoObj;

    if (id === 'raschetnaya_temperature_select') {
      infoObj = { arr: raschetnaya_temperatureValue, idName: 'temperature' };
    }
    if (id === 'raschetnoe_davlenie_select') {
      infoObj = { arr: raschetnoe_davlenieValue, idName: 'pressure' };
    }
    if (id === 'material_prokladok') {
      infoObj = { arr: material_prokladokValues, idName: 'gasket_material' };
    }
    if (id === 'raschetnaya_temperature_select' || id === 'raschetnoe_davlenie_select' || id === 'material_prokladok') {
      props.setFieldsData((prev) => ({
        ...prev,
        additionalData: {
          ...prev?.additionalData,
          [infoObj.idName]: infoObj.arr[val],
        },
      }));
    } else {
      props.setFieldsData((prev) => ({ ...prev, additionalData: { ...prev?.additionalData, [id]: val } }));
    }
  };

  const onClickHandler = () => {
    const resObj = {
      temperature: raschetnaya_temperatureValue[state.raschetnaya_temperature_select] || 150,
      pressure: raschetnoe_davlenieValue[state.raschetnoe_davlenie_select] || 16,
      pate_material: state?.material_plastin || 'AISI316',
      gasket_material: state.raschetnaya_temperature_select
        ? material_prokladokValues[state.raschetnaya_temperature_select]
        : 'EPDM',
    };
    props.setFieldsData((prev) => ({ ...prev, additionalData: resObj }));
    props.raschetButtonPressed(resObj, state.filtr_select);
  };

  return (
    <Paper style={getStyles().paper} elevation={3}>
      <Grid container style={getStyles().mainCell}>
        <Grid container style={getStyles().topItemCell}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...getStyles().headerText,
            }}
          >
            Дополнительные параметры
          </Typography>
        </Grid>
        <Grid container style={getStyles().middleItemCell}>
          {/* ---- Column 1 ---- */}

          <Grid container style={getStyles().middleItemColumn1}>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Расчетная температура
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Расчетное давление
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Материал пластин
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Материал прокладок
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Фильтр
              </Typography>
            </Grid>
          </Grid>

          {/* ---- Column 2 ---- */}

          <Grid container style={getStyles().middleItemColumn2}>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="raschetnaya_temperature_select"
                values={raschetnaya_temperatureTypes}
                selValue={state.raschetnaya_temperature_select}
                onChange={(event) => onDataInputChanged(event)}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="raschetnoe_davlenie_select"
                values={raschetnoe_davlenieTypes}
                selValue={state.raschetnoe_davlenie_select}
                onChange={(event) => onDataInputChanged(event)}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="material_plastin"
                value={state.material_plastin}
                value_type="only_value"
                disabled={true}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="material_prokladok"
                value={state.material_prokladok[state.raschetnaya_temperature_select]}
                value_type="only_value"
                disabled={true}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="filtr_select"
                values={state.filtrTypes}
                selValue={state.filtr_select}
                onChange={(event) => onDataInputChanged(event)}
              />
            </Grid>
            <MuiThemeProvider theme={theme}>
              <Box style={getStyles().buttonStyle}>
                <ButtonComponent width="220px" variant="contained" color="primary" onClick={() => onClickHandler()}>
                  <Typography style={getStyles().buttonTextStyle}>Расчет</Typography>
                </ButtonComponent>
              </Box>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const getStyles = () => {
  const styles = {
    paper: {
      background: '#FFFFFF',
      height: '653px',
      //width: '495px',
      width: '480px',
    },
    mainCell: {
      width: '100%',
      height: '100%',
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'column',
    },
    topItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    middleItemCell: {
      width: '100%',
      height: '89%',
      //border: '1px solid',
      //borderColor: 'cyan',
      flexDirection: 'row',
    },
    middleItemColumn1: {
      width: '51%',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'red',
    },
    middleItemColumn2: {
      width: '49%',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'green',
    },
    bottomItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    itemGridCell: {
      width: 'auto',
      height: '10%',
      //border: '1px solid',
      alignItems: 'center',
      paddingRight: '10px',
    },
    rowTextDescription: {
      marginLeft: '20px',
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    buttonStyle: { marginTop: '230px' },
    buttonTextStyle: {
      ...commonStyles.text.robotoRegular18,
      ...{ textTransform: 'none' },
    },
  };
  return styles;
};

export default RPTOPanel2Component;
