import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import commonStyles from '../../../commonUtils/commonStyles';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Grid container style={getStyles().root}>
        <Grid container justify="center" alignItems="center" style={getStyles().grid}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    root: {
      background: commonStyles.color.background,
      width: '100%',
      height: '100vh',
    },
    grid: {
      //border: '1px solid',
      //borderColor: 'red',
      height: '100%',
    },
  };
  return styles;
};

export default Layout;
