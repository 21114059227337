import React, { useState, useContext, useEffect } from 'react';
import { ThemeProvider, createMuiTheme, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import commonStyles from '../../commonUtils/commonStyles';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import TopBarComponent from './Components/TopBar/TopBarComponent';
import ArchiveTableComponent from './Components/ArchiveTable/ArchiveTableComponent';
import PDFViewComponent from '../Components/PDFViewComponent/PDFViewComponent';
import { GlobalContext } from '../../';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST, ROUTES_LIST, BASE_URL } from '../../constants';
import { useHistory } from 'react-router-dom';
import MainClass from '../../main/MainClass';
import {
  powerTypes,
  hot_teplonositelTypes,
  mass_rashodType,
  koncentrationPercentage,
} from '../../commonUtils/utilsOVIC';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
const raschetnaya_temperatureValue = [150, 180];
const raschetnoe_davlenieValue = [10, 16];

export default function ArchiveScreen() {
  const [modalOpen, setModalOpen] = useState(false);
  const [archive, setArchive] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pdfToken, setPdfToken] = useState('');
  const [uniqueObjects, setUniqueObjects] = useState([]);
  const [uniqueFilter, setUniqueFilter] = useState('');

  const { setGlobalLoading, setGlobalState } = useContext(GlobalContext);
  const { getRequest: getUniqueObjectNames } = useServerRequest(setGlobalLoading);
  const { getRequest: getArchive } = useServerRequest(setGlobalLoading);
  const { getRequest: pdfsGetStoreToken } = useServerRequest(setGlobalLoading);
  const { getRequest: getModelLink } = useServerRequest(setGlobalLoading);
  const { getRequest: addItemsToCart } = useServerRequest(setGlobalLoading);

  const history = useHistory();

  const downloadlink = `${BASE_URL}${API_ENDPOINTS_LIST.pdfs_store_download}/${pdfToken}/`;
  const printLink = `${BASE_URL}${API_ENDPOINTS_LIST.pdfs_store_view}/${pdfToken}/`;
  useEffect(() => {
    getUniqueObjectNames(API_ENDPOINTS_LIST.get_store_object_names_unique, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        setUniqueObjects(res.result);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
    getArchive(API_ENDPOINTS_LIST.get_store, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        //console.log('getArchive: ' + JSON.stringify(res));
        setArchive(res.result);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  }, [getUniqueObjectNames, getArchive, setGlobalState]);

  const splitString = (str, separator) => str.split(separator);

  const showCalculationPageHandler = () => {
    const {
      heatBalanceData: { N, coldSide, hotSide },
      additionalData,
    } = selectedRows[0];

    const hotTepSelected = hot_teplonositelTypes.indexOf(splitString(hotSide.conditionName, '-')[0]);
    const coldTepSelected = hot_teplonositelTypes.indexOf(splitString(coldSide.conditionName, '-')[0]);

    const obj = {
      power: N.value,
      power_type_select: powerTypes.indexOf(N.dimension),
      zapas: 10,
      hot_teplonositel_select: hotTepSelected,
      hot_koncentration_select:
        hotTepSelected !== 0
          ? koncentrationPercentage[hotTepSelected].indexOf(splitString(hotSide.conditionName, '-')[1])
          : 0,
      hot_mass_rashod: hotSide.props.Q_m.value,
      hot_temperature_vhod: hotSide.props.T_input.value,
      hot_temperature_vyhod: '',
      hot_poteri_davlenia: '',
      cold_teplonositel_select: coldTepSelected,
      cold_koncentration_select:
        coldTepSelected !== 0
          ? koncentrationPercentage[coldTepSelected].indexOf(splitString(coldSide.conditionName, '-')[1])
          : 0,
      cold_mass_rashod: coldSide.props.Q_m.value,
      mass_rashod_type_select: mass_rashodType.indexOf(hotSide.props.Q_m.dimension),
      cold_temperature_vhod: coldSide.props.T_input.value,
      cold_temperature_vyhod: '',
      cold_poteri_davlenia: '',
      poteri_davlenia_type_select: 1,
      automatic_data_calc_switch: false,
    };
    MainClass.mainDataObj.RPTOPanel1StateObject = obj;
    MainClass.mainDataObj.selectedReshenieData = selectedRows[0].tableData.id;
    MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject = {
      ...MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject,
      raschetnaya_temperature_select: raschetnaya_temperatureValue.indexOf(additionalData.temperature),
      raschetnoe_davlenie_select: raschetnoe_davlenieValue.indexOf(additionalData.pressure),
    };
    history.push(ROUTES_LIST.rptoCalculationPoverochniy);
  };

  const showCalculationHandler = () => {
    const selectedIds = selectedRows.map((item) => item.id);
    pdfsGetStoreToken(API_ENDPOINTS_LIST.pdfs_get_store_token, JSON.stringify(selectedIds), 'POST').then((res) => {
      if (res && res.status === 200) {
        //console.log(res.result);
        setPdfToken(res.result.token);
        setModalOpen(true);
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const getModelLinkHandler = (type) => {
    getModelLink(`${API_ENDPOINTS_LIST.get_models}/${type}/${selectedRows[0].id}`, '', 'GET').then((res) => {
      if (res && res.status === 200) {
        const { url } = res.result;
        window.location.assign(url);
      } else {
        if (res.status === 400) {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Файл не найден!',
            toastStyle: 'error',
          }));
        } else {
          setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
            toastStyle: 'error',
          }));
        }
      }
    });
  };

  const setUniqueFilterHandler = (data) => {
    setSelectedRows([]);
    setUniqueFilter(data);
  };

  const addToCartHandler = () => {
    const selectedIds = selectedRows.map((item) => item.id);
    addItemsToCart(API_ENDPOINTS_LIST.set_cart_data, JSON.stringify(selectedIds), 'POST').then((res) => {
      if (res && res.status === 200) {
        //console.log('addItemsToCart: ' + JSON.stringify(res));
        MainClass.shoppingCartGlobalData.cartItemsNum = Number(res.result.itemsCount);
        setSelectedRows([]);
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Объекты добавлены в корзину!',
          toastStyle: 'success',
        }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  const filterArchiveArray = (archive, uniqueFilter) => {
    return archive.filter((item) => item.nameOfObject === uniqueFilter);
  };

  return (
    <ThemeProvider theme={theme}>
      <PDFViewComponent
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        links={{
          downloadlink,
          printLink,
        }}
      />
      <HeaderComponent screenNumber={2} />
      <Grid container style={getStyles().topGrid}>
        <Grid container>
          <Grid item xs={11} md={10} lg={9} style={getStyles().infoPanel}>
            <TopBarComponent
              uniqueObjects={uniqueObjects}
              uniqueFilter={uniqueFilter}
              setUniqueFilter={setUniqueFilterHandler}
              selectedRows={selectedRows}
              showCalculation={showCalculationHandler}
              showCalculationPage={showCalculationPageHandler}
              getModelLink={getModelLinkHandler}
              addToCart={addToCartHandler}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} md={10} lg={9} style={getStyles().infoPanel}>
            <ArchiveTableComponent
              rows={!isEmpty(uniqueFilter) ? filterArchiveArray(archive, uniqueFilter) : archive}
              selectedRow={selectedRows}
              setSelectedRow={setSelectedRows}
            />
          </Grid>
        </Grid>
      </Grid>

      <FooterComponent />
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    topGrid: {
      marginTop: '175px',
      marginBottom: '56px',
    },
    infoPanel: {
      margin: '0 auto 20px',
    },
  };
  return styles;
};
