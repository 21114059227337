import React, { useState, useEffect, useContext } from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import commonStyles from '../../../../commonUtils/commonStyles';

import MainClass from '../../../../main/MainClass';

import TextInputComponent from '../../../Components/Elements/TextInput/TextInputComponent';
import DropDownComponent from '../../../Components/Elements/DropDown/DropDownComponent';

import { GlobalContext } from '../../../../index';
import ButtonComponent from '../../../Components/Elements/Button/ButtonComponent';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.blue,
    },
  },
});

const initialStateObject = {
  raschetnaya_temperature_select: 0,
  raschetnoe_davlenie_select: 1,
  material_plastin: 'AISI316',
  material_prokladok: ['EPDM', 'VITON'],
  filtr_select: 0,
  komponovka_select: 0,
  komponovka_type_variants: ['', ''],
  komponovka_type_field1: 0,
  komponovka_type_field2: 0,
  komponovkaTypes: [''],
  komponovkaAllTypes: [''],
  filtrTypes: [''],
  komponovka_MinMax_for_filter: [''],
};
const raschetnaya_temperatureValue = [150, 180];
const raschetnoe_davlenieValue = [10, 16];
const material_prokladokValues = ['EPDM', 'VITON'];

const raschetnaya_temperatureTypes = ['150 °C', '180 °C'];
const raschetnoe_davlenieTypes = ['10 атм', '16 атм'];

const RPTOPanel2Component = (props) => {
  const { setGlobalState } = useContext(GlobalContext);
  const [state, setState] = useState(initialStateObject);
  //let count = 0;
  useEffect(() => {
    //const { ptoList } = props;
    if (props.komponovkaData !== '') {
      const {
        ptoList,
        ptoListSelected,
        layouts,
        layoutsListSelected,
        currentLayoutValues,
        minMaxLayouts,
      } = props.komponovkaData;

      const resObj = {
        raschetnaya_temperature_select:
          MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject.raschetnaya_temperature_select,
        raschetnoe_davlenie_select: MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject.raschetnoe_davlenie_select,
        filtrTypes: ptoList,
        filtr_select: ptoListSelected,
        komponovkaTypes: layouts[ptoListSelected],
        komponovkaAllTypes: layouts,
        komponovka_select: layoutsListSelected,
        komponovka_type_variants: layouts[ptoListSelected][layoutsListSelected].split('+'),
        komponovka_type_field1: currentLayoutValues[0] !== undefined ? Number(currentLayoutValues[0]) : 0,
        komponovka_type_field2: currentLayoutValues[1] !== undefined ? Number(currentLayoutValues[1]) : 0,
        komponovka_MinMax_for_filter: minMaxLayouts,
      };
      MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject = resObj;
      setState((prevState) => ({
        ...prevState,
        ...resObj,
      }));
    }
  }, [props.komponovkaData]);

  useEffect(() => {
    setTimeout(() => {
      MainClass.mainDataObj.RPTOPoverochniyPanel2StateObject = state;
    }, 500);
  }, [state]);

  useEffect(() => {
    //check for error
    if (state.komponovka_MinMax_for_filter[0] !== '') {
      const testTwoFields = state.komponovka_type_variants[1] !== undefined ? true : false;
      const testMinVal =
        Number(state.komponovka_type_field1) + Number(state.komponovka_type_field2) <
        Number(state.komponovka_MinMax_for_filter[state.filtr_select].min)
          ? true
          : false;
      const testMaxVal =
        Number(state.komponovka_type_field1) + Number(state.komponovka_type_field2) >
        Number(state.komponovka_MinMax_for_filter[state.filtr_select].max)
          ? true
          : false;
      if (
        props.textFieldErrorObj.komponovka_type_field1 === true ||
        props.textFieldErrorObj.komponovka_type_field2 === true
      ) {
        props.setErrorField('komponovka_type_field1', false, 'komponovka_type_field2', false);
      }
      if (testMinVal) {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: testTwoFields
            ? 'Сумма двух полей этой компоновки не может быть меньше ' +
              state.komponovka_MinMax_for_filter[state.filtr_select].min +
              '!'
            : 'Значение этого поля не может быть меньше ' +
              state.komponovka_MinMax_for_filter[state.filtr_select].min +
              '!',
          toastStyle: 'error',
        }));
        if (testTwoFields) {
          props.setErrorField('komponovka_type_field1', true, 'komponovka_type_field2', true);
        } else {
          props.setErrorField('komponovka_type_field1', true, null, null);
        }
      }
      if (testMaxVal) {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: testTwoFields
            ? 'Сумма двух полей этой компоновки не может быть больше ' +
              state.komponovka_MinMax_for_filter[state.filtr_select].max +
              '!'
            : 'Значение этого поля не может быть больше ' +
              state.komponovka_MinMax_for_filter[state.filtr_select].max +
              '!',
          toastStyle: 'error',
        }));
        if (testTwoFields) {
          props.setErrorField('komponovka_type_field1', true, 'komponovka_type_field2', true);
        } else {
          props.setErrorField('komponovka_type_field1', true, null, null);
        }
      }
    }
  }, [state, setGlobalState]);

  const onDataInputChanged = (event) => {
    props.dataChangeEventHandler();

    const id = event.target.id;
    let val = event.target.value;

    if (id === 'komponovka_select') {
      let variants = state.komponovkaTypes[val];
      variants = variants.split('+');
      getDefaultValKomponovka(state.filtr_select, val);
      setState((prevState) => ({
        ...prevState,
        komponovka_type_variants: variants,
      }));
    }
    if (id === 'komponovka_type_field1') {
      if (val < 1) {
        val = state.komponovka_type_field1;
      }
    }

    if (id === 'komponovka_type_field2') {
      if (val < 1) {
        val = state.komponovka_type_field2;
      }
    }

    if (id === 'filtr_select') {
      getDefaultValKomponovka(val, 0);

      setState((prevState) => ({
        ...prevState,
        komponovkaTypes: state.komponovkaAllTypes[val],
        komponovka_type_variants: state.komponovkaAllTypes[val][0].split('+'),
        komponovka_select: 0,
      }));
    }

    setState((prevState) => ({
      ...prevState,
      [id]: val,
    }));

    var infoObj;

    if (id === 'raschetnaya_temperature_select') {
      infoObj = { arr: raschetnaya_temperatureValue, idName: 'temperature' };
    }
    if (id === 'raschetnoe_davlenie_select') {
      infoObj = { arr: raschetnoe_davlenieValue, idName: 'pressure' };
    }
    if (id === 'material_prokladok') {
      infoObj = { arr: material_prokladokValues, idName: 'gasket_material' };
    }
    if (id === 'raschetnaya_temperature_select' || id === 'raschetnoe_davlenie_select' || id === 'material_prokladok') {
      props.setFieldsData((prev) => ({
        ...prev,
        additionalData: {
          ...prev?.additionalData,
          [infoObj.idName]: infoObj.arr[val],
        },
      }));
    } else {
      props.setFieldsData((prev) => ({ ...prev, additionalData: { ...prev?.additionalData, [id]: val } }));
    }
  };
  const getDefaultValKomponovka = (filtrSelectVal, komponovkaSelectVal) => {
    let defaultVal;
    const check = state.komponovkaAllTypes[filtrSelectVal][komponovkaSelectVal].split('+').length > 1 ? true : false;
    if (check) {
      defaultVal =
        MainClass.userRole === 'user'
          ? Math.round(
              (Number(state.komponovka_MinMax_for_filter[filtrSelectVal].min) +
                Number(state.komponovka_MinMax_for_filter[filtrSelectVal].max)) /
                4
            )
          : 12;
    } else {
      defaultVal =
        MainClass.userRole === 'user'
          ? Math.round(
              (Number(state.komponovka_MinMax_for_filter[filtrSelectVal].min) +
                Number(state.komponovka_MinMax_for_filter[filtrSelectVal].max)) /
                2
            )
          : 12;
    }
    setState((prevState) => ({
      ...prevState,
      komponovka_type_field1: defaultVal,
      komponovka_type_field2: check ? defaultVal : 0,
    }));
  };

  const onClickHandler = () => {
    const resObj = {
      temperature: raschetnaya_temperatureValue[state.raschetnaya_temperature_select] || 150,
      pressure: raschetnoe_davlenieValue[state.raschetnoe_davlenie_select] || 16,
      pate_material: state?.material_plastin || 'AISI316',
      gasket_material: state?.raschetnaya_temperature_select
        ? material_prokladokValues[state.raschetnaya_temperature_select]
        : 'EPDM',
    };
    props.setFieldsData((prev) => ({ ...prev, additionalData: resObj }));
    props.raschetButtonPressed(resObj);
  };

  return (
    <Paper style={getStyles().paper} elevation={3}>
      <Grid container style={getStyles().mainCell}>
        <Grid container style={getStyles().topItemCell}>
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
              ...getStyles().headerText,
            }}
          >
            Дополнительные параметры
          </Typography>
        </Grid>
        <Grid container style={getStyles().middleItemCell}>
          {/* ---- Column 1 ---- */}

          <Grid container style={getStyles().middleItemColumn1}>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Расчетная температура
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Расчетное давление
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Материал пластин
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Материал прокладок
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Фильтр
              </Typography>
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular18,
                  ...getStyles().rowTextDescription,
                }}
              >
                Компоновка
              </Typography>
            </Grid>
          </Grid>

          {/* ---- Column 2 ---- */}

          <Grid container style={getStyles().middleItemColumn2}>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="raschetnaya_temperature_select"
                values={raschetnaya_temperatureTypes}
                selValue={state.raschetnaya_temperature_select}
                onChange={(event) => onDataInputChanged(event)}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="raschetnoe_davlenie_select"
                values={raschetnoe_davlenieTypes}
                selValue={state.raschetnoe_davlenie_select}
                onChange={(event) => onDataInputChanged(event)}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="material_plastin"
                value={state.material_plastin}
                value_type="only_value"
                disabled={true}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                id="material_prokladok"
                value={state.material_prokladok[state.raschetnaya_temperature_select]}
                value_type="only_value"
                disabled={true}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="filtr_select"
                values={state.filtrTypes}
                selValue={state.filtr_select}
                onChange={(event) => onDataInputChanged(event)}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <DropDownComponent
                id="komponovka_select"
                values={state.komponovkaTypes}
                selValue={state.komponovka_select}
                onChange={(event) => onDataInputChanged(event)}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                disabled={false}
                id={'komponovka_type_field1'}
                value={state.komponovka_type_field1}
                value_type={state.komponovka_type_variants[0]}
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.komponovka_type_field1}
              />
            </Grid>
            <Grid container style={getStyles().itemGridCell}>
              <TextInputComponent
                disabled={false}
                visible={state.komponovka_type_variants[1] !== undefined ? true : false}
                id={'komponovka_type_field2'}
                value={state.komponovka_type_field2}
                value_type={state.komponovka_type_variants[1] !== undefined ? state.komponovka_type_variants[1] : ''}
                input_type="number"
                onChange={(event) => onDataInputChanged(event)}
                fieldIncorrect={props.textFieldErrorObj.komponovka_type_field2}
              />
            </Grid>
            <MuiThemeProvider theme={theme}>
              <Box style={getStyles().buttonStyle}>
                <ButtonComponent width="220px" variant="contained" color="primary" onClick={() => onClickHandler()}>
                  <Typography style={getStyles().buttonTextStyle}>Расчет</Typography>
                </ButtonComponent>
              </Box>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const getStyles = () => {
  const styles = {
    paper: {
      background: '#FFFFFF',
      height: '653px',
      //width: '495px',
      width: '480px',
    },
    mainCell: {
      width: '100%',
      height: '100%',
      //border: '1px solid',
      //borderColor: 'red',
      flexDirection: 'column',
    },
    topItemCell: {
      width: '100%',
      height: '71px',
      //border: '1px solid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    middleItemCell: {
      width: '100%',
      height: '89%',
      //border: '1px solid',
      //borderColor: 'cyan',
      flexDirection: 'row',
    },
    middleItemColumn1: {
      width: '51%',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'red',
    },
    middleItemColumn2: {
      width: '49%',
      flexDirection: 'column',
      //border: '1px solid',
      //borderColor: 'green',
    },
    itemGridCell: {
      width: 'auto',
      height: '10%',
      //border: '1px solid',
      alignItems: 'center',
      paddingRight: '10px',
    },
    rowTextDescription: {
      marginLeft: '20px',
    },
    headerText: {
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    buttonStyle: { marginTop: '60px' },
    buttonTextStyle: {
      ...commonStyles.text.robotoRegular18,
      ...{ textTransform: 'none' },
    },
  };
  return styles;
};

export default RPTOPanel2Component;
