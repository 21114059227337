import React, { useState, useContext } from 'react';
import { Box, Container, createMuiTheme, Grid, TextField, ThemeProvider, Typography } from '@material-ui/core';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';
import HeatLoad from './Components/HeatLoad';
import NetworkParameters from './Components/NetworkParameters';
import HeatingSystem from './Components/HeatingSystem';
import VentilationSystem from './Components/VentilationSystem';
import HotWaterSupplySystem from './Components/HotWaterSupplySystem';
import Bundles from './Components/Bundles';
import Dimensions from './Components/Dimensions';
import AutomaticRegulation from './Components/automaticRegulation';

import { GlobalContext } from '../..';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../constants';

import commonStyles from '../../commonUtils/commonStyles';
import TextInputComponent from '../Components/Elements/TextInput/TextInputComponent';
import { validateText } from '../../commonUtils/commonUtils';
import Button from '../Components/Elements/Button/ButtonComponent';

const calculationITP = {
  name: '',
  heatingSystem: '',
  ventilationSystem: '',
  dhwSystem: '',
  temperatureScheduleWinterInput: '',
  temperatureScheduleWinterOutput: '',
  temperatureGraphBreakPointInput: '',
  temperatureGraphBreakPointOutput: '',
  pressureInput: '',
  pressureOutput: '',
  networkConnectionDiagram: '2',
  connectionTypeSO: 'dependent',
  regulationTypeSO: 'quality',
  regulationTypeSOOther: '',
  heatingEnvironmentSO: 'water',
  heatingEnvironmentEthyleneGlycolSO: '',
  heatingEnvironmentPropyleneGlycolSO: '',
  ptoReservationSO: 'no',
  ptoReservationOtherSO: '',
  temperatureGraphSOInput: '',
  temperatureGraphSOOutput: '',
  hydraulicResistanceSO: '',
  waterVolumeSO: '',
  designPressureSO: '',
  staticPressureSO: '',
  circulationPumpCOReservationSO: false,
  circulationPumpCODoubleSO: false,
  circulationPumpCOReservationFrequencyRegulationSO: false,
  connectionTypeSV: 'independent straight',
  connectionTypeSVOther: '',
  heatingEnvironmentSV: 'water',
  heatingEnvironmentEthyleneGlycolSV: '',
  heatingEnvironmentPropyleneGlycolSV: '',
  ptoReservationSV: 'no',
  ptoReservationOtherSV: '',
  temperatureGraphSVInput: '',
  temperatureGraphSVOutput: '',
  hydraulicResistanceSV: '',
  waterVolumeSV: '',
  designPressureSV: '',
  staticPressureSV: '',
  circulationPumpCOReservationSV: false,
  circulationPumpCODoubleSV: false,
  circulationPumpCOReservationFrequencyRegulationSV: false,
  connectionTypeGVS: 'one step',
  designMixedCircuit: '',
  hourlyConsumption: '',
  ptoReservationGVS: 'no',
  ptoReservationOtherGVS: '',
  temperatureGraphGVSInput: '',
  temperatureGraphGVSOutput: '',
  hydraulicResistance: '',
  designPressureGVS: '',
  minColdWaterPressure: '',
  staticPressureGVS: '',
  waterConsumptionGVS: '',
  circulationPumpCOReservationGVS: false,
  circulationPumpCODoubleGVS: false,
  circulationPumpCOReservationFrequencyRegulationGVS: false,
  makeUpPump: false,
  solenoidValve: false,
  expansionTank: false,
  sump: false,
  filter: false,
  pressureRegulator: false,
  general: false,
  separatelyEachSystem: false,
  countingColdWaterConsumption: false,
  powerSupply: '1х230В',
  environmentTemperature: '',
  minimumInstallationOpeningWidth: '',
  minimumInstallationOpeningHeight: '',
  roomForInstallationLength: '',
  roomForInstallationWidth: '',
  roomForInstallationHeight: '',
  automaticRegulationSO: false,
  automaticRegulationSV: false,
  automaticRegulationGVS: false,
  automaticRegulationUnit: false,
  description: '',
};

const fieldName = {
  name: 'Название объекта',
  description: 'Дополнительные требования',
};

const baseFormGridParameters = {
  md: {
    label: 3,
    form: 9,
  },
  xs: {
    label: 12,
    form: 12,
  },
};

const sendTitle = 'Отправить';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const ITPCalculationScreen = () => {
  const { titleText, labelPosition, box, radioGroup, formContainer, sendButton, inputRightMargin } = getStyles();

  const [data, setDataValue] = useState(calculationITP);
  const { setGlobalState, setGlobalLoading } = useContext(GlobalContext);
  const { getRequest: raschetITP } = useServerRequest(setGlobalLoading);
  const { consultant } = useContext(GlobalContext);

  const setValueHandler = (event, field) => {
    const val = event.target.value;
    if (field !== 'name') {
      setDataValue((prev) => ({ ...prev, [field]: val }));
    } else if (val.length <= 100) {
      setDataValue((prev) => ({ ...prev, [field]: val }));
    }
  };

  const setBoolHandler = (event, type) => {
    const val = event.target.checked;
    setDataValue((prev) => ({ ...prev, [type]: val }));
  };

  const sendData = () => {
    //console.log(JSON.stringify(data));
    //console.log(document.documentElement.innerHTML);
    //everything is correct - send data to server
    const dataForServer = document.documentElement.innerHTML;

    raschetITP(API_ENDPOINTS_LIST.raschet_block_itp, dataForServer, 'POST', true, 'text/html').then((res) => {
      if (res && res.status === 200) {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          //toastAutohideDuration: 15000,
          toastMessage:
            'Расчет отправлен на рассмотрение инженеру-консультанту: ' +
            (consultant !== null ? consultant.consultantName : '') +
            ', тел.: ' +
            (consultant !== null ? consultant.consultantPhone : '') +
            ' Результат расчета будет отправлен вам по электронной почте.',
          toastStyle: 'success',
        }));
      } else {
        setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: 'Ошибка запроса к серверу! ' + res.code + ' ' + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent screenNumber={12} />
        <Container style={getStyles().container}>
          <Box style={{ ...box }}>
            <Grid container style={{ ...formContainer }} spacing={3}>
              <Grid item xs={12} md={2} style={{ ...labelPosition }}>
                <Typography
                  style={{
                    ...commonStyles.text.robotoRegular16,
                  }}
                >
                  {fieldName.name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={10}>
                <TextInputComponent
                  value={data.name}
                  variant={'outlined'}
                  value_type={''}
                  onChange={(e) => setValueHandler(e, 'name')}
                />
              </Grid>
            </Grid>
          </Box>

          <HeatLoad
            data={data}
            setValueHandler={setValueHandler}
            stylesContext={{ titleText, box, formContainer, labelPosition }}
          />

          <NetworkParameters
            data={data}
            formGridParameters={baseFormGridParameters}
            setValueHandler={setValueHandler}
            stylesContext={{ titleText, box, formContainer, labelPosition, inputRightMargin }}
          />

          <HeatingSystem
            data={data}
            formGridParameters={baseFormGridParameters}
            setValueHandler={setValueHandler}
            setBoolHandler={setBoolHandler}
            stylesContext={{ titleText, box, formContainer, labelPosition, radioGroup, inputRightMargin }}
          />

          <VentilationSystem
            data={data}
            formGridParameters={baseFormGridParameters}
            setValueHandler={setValueHandler}
            setBoolHandler={setBoolHandler}
            stylesContext={{ titleText, box, formContainer, labelPosition, radioGroup, inputRightMargin }}
          />

          <HotWaterSupplySystem
            data={data}
            formGridParameters={baseFormGridParameters}
            setValueHandler={setValueHandler}
            setBoolHandler={setBoolHandler}
            stylesContext={{ titleText, box, formContainer, labelPosition, radioGroup, inputRightMargin }}
          />

          <Bundles
            data={data}
            setValueHandler={setValueHandler}
            setBoolHandler={setBoolHandler}
            stylesContext={{ titleText, box }}
          />

          <Grid container>
            <Grid item xs={12} md={8}>
              <Dimensions
                data={data}
                setValueHandler={setValueHandler}
                stylesContext={{ titleText, box, formContainer, labelPosition }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <AutomaticRegulation data={data} setBoolHandler={setBoolHandler} stylesContext={{ titleText, box }} />
            </Grid>
          </Grid>

          <Box style={{ ...box }}>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular24,
                ...titleText,
              }}
            >
              {fieldName.description}
            </Typography>
            <Grid container style={{ ...formContainer }} spacing={3}>
              <Grid item xs={12} md={12}>
                <TextField
                  multiline={true}
                  fullWidth={true}
                  variant="outlined"
                  rows={15}
                  value={data.description}
                  onChange={(e) => setValueHandler(e, 'description')}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <Button onClick={sendData} style={{ ...sendButton }} disabled={!validateText(data.name)}>
                    {sendTitle}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <FooterComponent />
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  return {
    container: {
      marginTop: '188px',
      marginBottom: '57px',
    },
    titleText: {
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: commonStyles.color.blue,
    },
    box: {
      background: commonStyles.color.white,
      margin: '20px 5px',
      //boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      boxShadow: '0 0px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      paddingBottom: '20px',
    },
    formContainer: {
      padding: '0 20px',
    },
    labelPosition: {
      display: 'flex',
      alignItems: 'center',
    },
    radioGroup: {
      width: '100%',
    },
    sendButton: {
      width: '210px',
    },
    inputRightMargin: {
      marginRight: '10px',
    },
  };
};

export default ITPCalculationScreen;
