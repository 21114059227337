import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Grid,
  //Paper,
  AppBar,
  Button,
} from '@material-ui/core';

import commonStyles from '../../../commonUtils/commonStyles';
import ConsultantModalComponent from '../ConsultantModalComponent/ConsultantModalComponent';
import { API_ENDPOINTS_LIST } from '../../../constants';
import { GlobalContext } from '../../..';
import useServerRequest from '../../../hooks/serverRequest';

import MainClass from '../../../main/MainClass';

function FooterComponent() {
  const { setGlobalLoading, setGlobalState } = useContext(GlobalContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [consultantData, setConsultantDataState] = useState(null);

  const { getRequest: getConsultantInfo } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Footer user role: ' + MainClass.userRole);
    //setUserRole(MainClass.userRole);
    if (MainClass.userRole !== 'admin' && MainClass.userRole !== 'calculator') {
      if (!consultantData) {
        getConsultantInfo(API_ENDPOINTS_LIST.get_consultant, '', 'GET').then((res) => {
          if (res && res.status === 200) {
            setConsultantDataState(res.result);
            //console.log('Footer consultant: ' + JSON.stringify(res));
          } else {
            setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: `Ошибка при получении данных консультанта`,
              toastStyle: 'error',
            }));
          }
        });
      }
    }
  }, [MainClass.userRole]);

  return (
    <AppBar
      position="fixed"
      style={{
        background: commonStyles.color.blue,
        top: 'auto',
        bottom: 0,
        height: '46px',
      }}
    >
      <Grid
        container
        style={{
          //background: commonStyles.color.blue,
          height: '36px',
          width: '100%',
          flex: 1,
          flexDirection: 'row',
          //justifyContent: 'space-evenly',
          //marginLeft: '10px',
        }}
        alignItems="center"
      >
        <Grid container style={{ justifyContent: 'flex-start', width: '50%' }}>
          <Typography
            style={{
              marginLeft: '20px',
              fontSize: '16px',
              fontFamily: '"Roboto", sans-serif',
              fontStyle: 'normal',
              textTransform: 'uppercase',
            }}
          >
            Ваш персональный инженер-консультант
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '50%',
          }}
          alignItems="center"
        >
          <Typography
            style={{
              marginRight: '20px',
              fontSize: '16px',
              fontFamily: '"Roboto", sans-serif',
              fontStyle: 'normal',
              textTransform: 'uppercase',
            }}
          >
            {consultantData ? consultantData.consultantName : ''}
          </Typography>
          <Typography
            style={{
              marginRight: '20px',
              fontSize: '16px',
              fontFamily: '"Roboto", sans-serif',
              fontStyle: 'normal',
            }}
          >
            {consultantData ? consultantData.consultantPhone : ''}
          </Typography>
          <Typography
            style={{
              marginRight: '20px',
              fontSize: '16px',
              fontFamily: '"Roboto", sans-serif',
              fontStyle: 'normal',
            }}
          >
            {consultantData ? consultantData.email : ''}
          </Typography>
          <Button variant="outlined" size="small" onClick={() => setModalOpen(true)} style={getStyles().btn}>
            Написать консультанту
          </Button>
        </Grid>
      </Grid>
      <ConsultantModalComponent open={modalOpen} handleClose={() => setModalOpen(false)} />
    </AppBar>
  );
}

const getStyles = () => {
  const styles = {
    btn: {
      fontWeight: 600,
      fontSize: '16px',
      textTransform: 'none',
      color: commonStyles.color.white,
      borderColor: commonStyles.color.white,
      marginRight: 20,
    },
  };
  return styles;
};

export default FooterComponent;
