import React from 'react';
import { Paper, Container, Box, Grid } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import commonStyles from '../../commonUtils/commonStyles';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import FooterComponent from '../Components/FooterComponent/FooterComponent';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.blue,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const InWorkScreen = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box className="root-container">
        <HeaderComponent screenNumber={11} />
        <Container className="aaa" style={getStyles().tableContainer}>
          <Grid container style={getStyles().root}>
            <Grid container justify="center" alignItems="center" style={getStyles().grid}>
              <Grid item>
                <Alert severity="warning">Данный раздел Личного кабинета ЭТРА-ОНЛАЙН находится в разработке</Alert>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <FooterComponent />
      </Box>
    </ThemeProvider>
  );
};

const getStyles = () => {
  return {
    root: {
      background: commonStyles.color.background,
      width: '100%',
      height: '100vh',
    },
    grid: {
      //border: '1px solid',
      //borderColor: 'red',
      height: '100%',
    },
    tableContainer: {
      //height: '100vh',
      //width: '100%',
      //border: '1px solid',
      //borderColor: 'red',
    },
  };
};

export default InWorkScreen;
