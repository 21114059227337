var singleton = new (function () {
  /*
  this.publicTestVar = {
    prop1: 'test',
  };
  var privateTestVar = null;

  this.init = () => {
    privateTestVar = 'test private';
  };

  this.getTestVar = () => {
    return privateTestVar;
  };

  this.setTestVar = (val) => {
    privateTestVar = val;
  };
  */
  /*
  this.getfetchBaseURL = () => {
    return 'https://api.etrann-online.com'; //'https://cors-anywhere.herokuapp.com/' + 'http://18.191.31.212:3000';
  };
  */

  var screenNames = [
    'Расчет РПТО',
    'Личные данные',
    'Архив расчетов',
    'Каталог 3D моделей',
    'Поверочный расчет',
    'Разрешительные документы',
    'Каталоги и буклеты НПО ЭТРА',
    'Панель администрирования',
    'Формирование коммерческого предложения',
    'Коммерческие предложения',
    'Счета, оплаты, отгрузки',
    'Раздел в разработке',
    'Расчет блочного ИТП',
  ];

  this.getScreenNames = () => {
    return screenNames;
  };

  this.mainDataObj = {
    //OVIC
    RPTOPanel1StateObject: {
      power: 0.647,
      power_type_select: 3,
      zapas: 10,
      hot_teplonositel_select: 0,
      hot_koncentration_select: 0,
      hot_mass_rashod: 21.599,
      hot_temperature_vhod: 70,
      hot_temperature_vyhod: 40,
      hot_poteri_davlenia: 3,
      cold_teplonositel_select: 0,
      cold_koncentration_select: 0,
      cold_mass_rashod: 10.803,
      mass_rashod_type_select: 1,
      cold_temperature_vhod: 5,
      cold_temperature_vyhod: 65,
      cold_poteri_davlenia: 3,
      poteri_davlenia_type_select: 1,
      automatic_data_calc_switch: true,
    },
    RPTOPoverochniyPanel1StateObject: {
      power: '',
      power_type_select: 3,
      zapas: 10,
      hot_teplonositel_select: 0,
      hot_koncentration_select: 0,
      hot_mass_rashod: 21.599,
      hot_temperature_vhod: 70,
      hot_temperature_vyhod: '',
      hot_poteri_davlenia: '',
      cold_teplonositel_select: 0,
      cold_koncentration_select: 0,
      cold_mass_rashod: 10.803,
      mass_rashod_type_select: 1,
      cold_temperature_vhod: 5,
      cold_temperature_vyhod: '',
      cold_poteri_davlenia: '',
      poteri_davlenia_type_select: 1,
      automatic_data_calc_switch: false,
    },
    RPTOPoverochniyPanel2StateObject: {
      raschetnaya_temperature_select: 0,
      raschetnoe_davlenie_select: 0,
      material_plastin: 'AISI316',
      material_prokladok: ['EPDM', 'VITON'],
      filtr_select: 0,
      komponovka_select: 0,
      komponovka_type_variants: ['', ''],
      komponovka_type_field1: 0,
      komponovka_type_field2: 0,
      komponovkaTypes: [''],
      komponovkaAllTypes: [''],
      filtrTypes: [''],
      komponovka_MinMax_for_filter: [],
    },
    selectedReshenieData: null, //'ЭТ-019C-61|1x(30HH)', //'ЭТ-019C-43|1x(12HH+9HL)',
    rtoFieldsData: null,
    //Technology
    TechnologyPanel1StateObject: {
      power: 0.647,
      power_type_select: 3,
      zapas: 10,
      hot_teplonositel_select: 0,
      hot_koncentration_select: 0,
      hot_mass_rashod: 0,
      hot_temperature_vhod: 120,
      hot_temperature_vyhod: 105,
      hot_poteri_davlenia: 3,
      cold_teplonositel_select: 0,
      cold_koncentration_select: 0,
      cold_mass_rashod: 10.803,
      mass_rashod_type_select: 1,
      cold_temperature_vhod: 5,
      cold_temperature_vyhod: 65,
      cold_poteri_davlenia: 3,
      poteri_davlenia_type_select: 1,
      automatic_data_calc_switch: true,
    },
    //Technology DGVS
    TechnologyDGVSPanel1StateObject: {
      power: 300,
      power_type_select: 0,
      zapas: 10,
      hot_teplonositel_select: 0,
      hot_koncentration_select: 0,
      hot_mass_rashod: 0,
      hot_temperature_vhod: 70,
      hot_temperature_vyhod: 40,
      hot_poteri_davlenia: 3,
      cold_teplonositel_select: 0,
      cold_koncentration_select: 0,
      cold_mass_rashod: 4.307,
      mass_rashod_type_select: 1,
      cold_temperature_vhod: 5,
      cold_temperature_vyhod: 65,
      cold_poteri_davlenia: 3,
      poteri_davlenia_type_select: 1,
      rashod_greyushego_teplonositel: 4.923,
      rashod_greyushego_teplonositel_type_select: 1,
      firstState_load_percentage: 55,
      automatic_data_calc_switch: true,
    },
    TechnologyDGVSPanel2StateObject: {
      raschetnaya_temperature_select: 0,
      raschetnoe_davlenie_select: 1,
      material_plastin: 'AISI316',
      material_prokladok: ['EPDM', 'VITON'],
      filtr_select: 0,
      filtrTypes: [],
      heatingSystem: '',
      heatingType: '',
      returnFlowHeatLoad: '',
      scheduleHeatLoad: '',
      scheduleHeatLoadType: 'кВт',
      heatingScheduleFrom: '',
      heatingScheduleTo: '',
    },
  };

  this.balanceCurrent = 'Normal';

  this.getCurrentBalanceType = () => {
    return this.balanceCurrent;
  };

  this.setCurrentBalanceType = (balance_type) => {
    this.balanceCurrent = balance_type;
  };

  //Init method
  this.init = () => {};

  // User current role
  this.userRole = '';

  //

  //Data for Reshenie table from server

  var raschetResultData = null;

  this.getRaschetResultData = () => {
    return raschetResultData;
  };

  this.setRaschetResultData = (data) => {
    raschetResultData = data;
  };

  var raschetResultDataDGVS = null;

  this.getRaschetResultDataDGVS = () => {
    return raschetResultDataDGVS;
  };

  this.setRaschetResultDataDGVS = (data) => {
    raschetResultDataDGVS = data;
  };

  //Data for Reshenie table from server (Poverochniy)

  var poverochniyRaschetResultData = null;

  this.getPoverochniyRaschetResultData = () => {
    return poverochniyRaschetResultData;
  };

  this.setPoverochniyRaschetResultData = (data) => {
    poverochniyRaschetResultData = data;
  };

  //PARSING
  this.getKomponovkaFromData = (data) => {
    const reshenieId = this.mainDataObj.selectedReshenieData;
    const selectedPto = reshenieId.substring(0, reshenieId.lastIndexOf('-')).split('-').slice(0, 2).join('-');
    let selectedKomponovka = reshenieId.substring(reshenieId.lastIndexOf('(') + 1, reshenieId.lastIndexOf(')'));
    const selectedKomponovkaArr = [];
    selectedKomponovka.split('+').forEach((obj) => {
      obj = obj.replace(/[0-9]/g, '');
      selectedKomponovkaArr.push(obj);
    });
    const selectedKomponovkaValues = selectedKomponovka.match(/\d+/g);
    selectedKomponovka = selectedKomponovkaArr.join('+');
    let selectedPtoIndex = 0;
    let selectedKomponovkaIndex = 0;
    let ptoList = [];
    let layouts = [];
    let minMaxLayouts = [];
    data.forEach((obj, index) => {
      ptoList.push(obj.pto);
      layouts.push(obj.layout);
      minMaxLayouts.push(obj.number_of_channels);
      if (obj.pto === selectedPto) {
        selectedPtoIndex = index;
        obj.layout.forEach((layObj, index) => {
          if (layObj === selectedKomponovka) {
            selectedKomponovkaIndex = index;
          }
        });
      }
    });
    return {
      ptoList: ptoList,
      ptoListSelected: selectedPtoIndex,
      layouts: layouts,
      layoutsListSelected: selectedKomponovkaIndex,
      currentLayoutValues: selectedKomponovkaValues,
      minMaxLayouts: minMaxLayouts,
    };
  };

  this.getKomponovkaFromDataRaschetchik = (data) => {
    //const reshenieId = this.mainDataObj.selectedReshenieData;
    //const selectedPto = reshenieId.substring(0, reshenieId.lastIndexOf('-')).split('-').slice(0, 2).join('-');
    //let selectedKomponovka = reshenieId.substring(reshenieId.lastIndexOf('(') + 1, reshenieId.lastIndexOf(')'));
    //const selectedKomponovkaArr = [];
    /*
    selectedKomponovka.split('+').forEach((obj) => {
      obj = obj.replace(/[0-9]/g, '');
      selectedKomponovkaArr.push(obj);
    });
    */
    //const selectedKomponovkaValues = selectedKomponovka.match(/\d+/g);
    //selectedKomponovka = selectedKomponovkaArr.join('+');
    let selectedPtoIndex = 0;
    let selectedKomponovkaIndex = 0;
    let ptoList = [];
    let layouts = [];
    let minMaxLayouts = [];
    data.forEach((obj, index) => {
      ptoList.push(obj.pto);
      layouts.push(obj.layout);
      minMaxLayouts.push(obj.number_of_channels);
      /*
      if (obj.pto === selectedPto) {
        selectedPtoIndex = index;
        obj.layout.forEach((layObj, index) => {
          if (layObj === selectedKomponovka) {
            selectedKomponovkaIndex = index;
          }
        });
      }
      */
    });
    return {
      ptoList: ptoList,
      ptoListSelected: selectedPtoIndex,
      layouts: layouts,
      layoutsListSelected: selectedKomponovkaIndex,
      currentLayoutValues: [12, 12],
      minMaxLayouts: minMaxLayouts,
    };
  };

  // Set 3d models data
  var modelsResultData = null;

  this.setModelsArr = (data) => {
    return (modelsResultData = data);
  };

  this.getModelsArr = () => {
    return modelsResultData;
  };
  //

  //Shopping cart
  this.shoppingCartGlobalData = {
    cartItemsNum: 0,
    userDiscount: 0,
    terminals: '',
  };
  //
})();

export default singleton;
